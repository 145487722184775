<template>
  <CSidebar
    fixed
    :minimize="minimize"
    :show="show"
    @update:show="(value) => $store.commit('set', ['sidebarShow', value])"
  >
    <CSidebarBrand
      class="d-md-down-none"
      to="/dashboard"
    >
      <strong>{{ $t("nav.title") }}</strong>

    </CSidebarBrand>
    <CRenderFunction
      flat
      :content-to-render="navDatas"
    />
    <CSidebarMinimizer
      class="d-md-down-none"
      @click.native="$store.commit('set', ['sidebarMinimize', !minimize])"
    />
  </CSidebar>
</template>

<script>
import config from '@/config'
const ROLE = {
  ADMIN: "yado_operator_admin",
  STAFF: "yado_operator_staff",
  OPERATOR: "yado_operator",
  MEMBER: "liberty_member",
};

export default {
  name: "TheSidebar",

  data() {
    return {
      /** アカウントが使用可能なルート情報 */
      routerData: {},
      /** 選択中ロール */
      selectedRole: "",
    };
  },

  created() {
    // ルート情報設定用に、選択中ロールに初期ロールを設定する
    this.selectedRole = this.initialRole;
    this.updateRouterData(this.selectedRole);
  },

  computed: {
    show() {
      return this.$store.state.sidebarShow;
    },
    minimize() {
      return this.$store.state.sidebarMinimize;
    },

    navDatas() {
      // 選択中のロールでルート情報更新
      this.updateRouterData(this.selectedRole);

      const menuDataStructure = [
        {
          _name: "CSidebarNav",
          _children: this.routerData,
        },
      ];

      return menuDataStructure;
    },

    /** 表示用の初期ロールを取得 */
    initialRole() {
      // アカウントのロールリスト
      const roles = this.$keycloak.realmAccess.roles;
      // console.log(roles)

      // 優先度に応じたロールを返す
      if (roles.includes(ROLE.ADMIN)) return ROLE.ADMIN;
      if (roles.includes(ROLE.STAFF)) return ROLE.STAFF;
      if (roles.includes(ROLE.OPERATOR)) return ROLE.OPERATOR;

      return ROLE.MEMBER


    },
  },

  methods: {

    /** ルート情報更新 */
    updateRouterData(selectedRole) {
      // 全てのルート情報を設定
      this.debugLog(selectedRole);

      // NOTE: i18n対応のためここで設定すること
      const allRouterDatas = [
        //#region ポータル
        {
          _name: "CSidebarNavTitle",
          _children: [this.$t("routerName.Portal")],
          _class: "parent_tab",
          viewRole: [ROLE.STAFF],
          version: 1,
        },
        {
          _name: "CSidebarNavItem",
          name: this.$t("routerName.Dashboard"),
          to: "/staff/Dashboard",
          icon: "cil-speedometer",
          viewRole: [ROLE.STAFF],
          version: 1,
        },
        //#endregion ポータル

        //#region 宿管理
        {
          _name: "CSidebarNavTitle",
          _children: [this.$t("routerName.FacilityTitle")],
          _class: "parent_tab",
          viewRole: [ROLE.STAFF],
          version: 1,
        },
        {
          _name: "CSidebarNavItem",
          name: this.$t("routerName.Facility"),
          to: "/staff/Facility",
          icon: "cil-home",
          viewRole: [ROLE.STAFF],
          version: 1,
        },
        //#endregion 宿管理


        //#region ログアウト
        {
          _name: "CSidebarNavTitle",
          _children: [this.$t("routerName.Logout")],
          _class: "parent_tab",
          viewRole: [ROLE.STAFF],
          version: 1,
        },
        {
          _name: "CSidebarNavItem",
          name: this.$t("routerName.Logout"),
          to: "/logout",
          icon: "cil-room",
          viewRole: [ROLE.STAFF],
          version: 1,
        }
        //#endregion ログアウト
      ];

      // 使用可能なルート情報に更新する
      this.routerData = allRouterDatas.filter((e) =>
        e.version <= config.VERSION && e.viewRole.some((v) => v == selectedRole)
      );
    },

    /**
     * ロールの表示ラベルを取得
     * @param value
     */
    getRoleLabel(value) {
      // 表示ラベル
      let label;

      // ロールに応じた表示ラベルを取得
      switch (value) {
        case ROLE.ADMIN:
          label = this.$t("nav.role.admin");
          break;
        case ROLE.STAFF:
          label = this.$t("nav.role.staff");
          break;
        case ROLE.OPERATOR:
          label = this.$t("nav.role.operator");
          break;
        default:
          label = this.$t("nav.role.member");
          break;
      }

      // 表示ラベルを返す
      return label;
    },
  },
};
</script>